<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ $t("settings.nav2") }}</h4>
    </div>
    <div v-loading="loading" class="p-20">
      <el-form
        ref="ruleForm"
        class="input-form mx-auto"
        :model="formData"
        :rules="formData.verified >= 2 ? [] : rules"
      >
        <el-form-item prop="old_password">
          <el-input
            v-model="formData.old_password"
            prefix-icon="el-icon-unlock"
            show-password
            :placeholder="$t('settings_password.placeholder1')"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="formData.password"
            prefix-icon="el-icon-lock"
            show-password
            :placeholder="$t('settings_password.placeholder2')"
          />
        </el-form-item>
        <el-form-item prop="re_password">
          <el-input
            v-model="formData.re_password"
            prefix-icon="el-icon-lock"
            show-password
            :placeholder="$t('settings_password.placeholder3')"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-s-promotion"
            class="w-100"
            native-type="button"
            @click="submitForm"
            >{{ $t("settings_password.submit") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    //验证重复密码
    var repasswordValidator = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("login.rules3")));
      } else if (value !== this.formData.password) {
        callback(new Error(this.$t("login.rules3_2")));
      } else {
        callback();
      }
    };
    return {
      locel: localStorage.getItem("locale") || "en-US",

      loading: false,
      formData: {
        old_password: null,
        password: null,
        re_password: null,
      },
      rules: {
        old_password: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("settings_password.placeholder1"),
          },
        ],
        password: [
          {
            required: true,
            min: 6,
            trigger: "blur",
            message: this.$t("login.rules2"),
          },
        ],
        re_password: [
          {
            required: true,
            min: 6,
            trigger: "blur",
            message: this.$t("login.rules3"),
          },
          { validator: repasswordValidator, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm: async function () {
      let that = this;
      try {
        await this.$refs.ruleForm.validate();
        if (this.formData.password != this.formData.re_password) {
          this.$message({
            message: that.$t("settings_password.message"),
            type: "error",
          });
          return;
        }
        this.loading = true;
        await this.$request.post("v1/user/change_password", this.formData);
        this.formData.old_password = null;
        this.formData.password = null;
        this.formData.re_password = null;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 320px;
  height: 180px;
}
</style>